import React, { useState } from "react";
import { Container, Row, Col, Tab, Nav, Modal } from "react-bootstrap";

import projImg1 from "../assets/img/project_1.png";
import projImg2 from "../assets/img/project_2.png";
import projImg3 from "../assets/img/project_3.png";


import colorSharp2 from "../assets/img/color-sharp2.png";
import "../App.css";



export const ProjectCard = ({ title, description, imgUrl, onCardClick }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={onCardClick}>
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};

export const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProjectUrl, setSelectedProjectUrl] = useState(null);

  const projects = [
    {
      title: "Employee Attrition",
      description: "Uncovering the factors that lead to employee attrition",
      imgUrl: projImg1,
      url: "/projects/employee_attrition.html", // Relative URL zum öffentlichen Ordner
    },
    {
      title: "Synthetic Currency Portfolio",
      description: "Construct a synthetic currency portfolio with minimal exposure",
      imgUrl: projImg2,
      url: "/projects/trading_basket.html", // Relative URL zum öffentlichen Ordner
    },
    {
      title: "Analyzing the Impact of Influencer Marketing",
      description: "The analysis aims to provide insights on influencer marketing ",
      imgUrl: projImg3,
      url: "/projects/anova.html", // Relative URL zum öffentlichen Ordner
    },
    // Weitere Projekte hier hinzufügen...
  ];

  const handleCardClick = (url) => {
    setSelectedProjectUrl(url);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProjectUrl(null);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <div>
              <h2>Projects</h2>
              <p>Welcome to my Projects showcase! This is where I turn ideas into reality. Each project here represents a unique journey.</p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                </Nav>
                <Tab.Content id="slideInUp">
                  <Tab.Pane eventKey="first">
                    <Row>
                      {projects.map((project, index) => (
                        <ProjectCard
                          key={index}
                          title={project.title}
                          description={project.description}
                          imgUrl={project.imgUrl}
                          onCardClick={() => handleCardClick(project.url)}
                        />
                      ))}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />

      <Modal show={showModal} onHide={closeModal} dialogClassName="custom-modal">
  <Modal.Header style={{ backgroundColor: 'transparent', border: 'none' }}>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <button className="close" onClick={closeModal} style={{ color: 'white', position: 'absolute', top: '5px', right: '50px', zIndex: '1050',fontSize: '50px', }}>
    &times; {/* Use the times symbol (×) for the close button */}
  </button>
  <Modal.Body>
    {/* Hier den ausgewählten HTML-Inhalt anzeigen */}
    {selectedProjectUrl && (
      <iframe title="Project Content" src={selectedProjectUrl} style={{ width: "100%", height: "500px" }} />
    )}
  </Modal.Body>
  {/* Entfernen Sie den Modal.Footer-Bereich, wenn Sie keine Footer-Buttons möchten */}
</Modal>

    </section>
  );
};
